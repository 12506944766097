@import "~antd/dist/antd.css";

/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
:root {
  --SD100: #0b0d0f;
  --SD075: #16191d;
  --SD050: #2e3338;
  --SD025: #464c53;
  --SD010: #777f88;
  --SL100: #fff;
  --SL075: #fcfcfc;
  --SL050: #f2f2f3;
  --SL025: #e5e5e6;
  --S100: #c60000;
  --S075: #ed0000;
  --S050: #ff1515;
  --S025: #ff6363;
}

body {
  background-color: var(--SD100);
  color: var(--SL100);
}
h1.ant-typography {
  color: var(--SD010);
}

.centred {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.accountDataCArd {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 200px;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
  padding: 20px;
  background-color: #f5f4f4;
  border-radius: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  flex: 0.3;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 40px 20px;
  background-color: #000;
  border-radius: 20px;
}
.card .ant-typography {
  color: var(--SL075);
}
.card h5.ant-typography {
  color: var(--SD010);
}
.cardBody {
  font-size: 30px;
}

.tableButton {
  margin: 2px;
}

.blockInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.blockInfoTitle {
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.blockInfoData {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  border-radius: 20px;
}
.blockInfoData {
  background-color: none;
}
.blockInfoData .ant-typography {
  color: var(--SL025);
}
.blockInfoData > div .ant-row strong {
  color: var(--SD010);
}
.blockInfoData > div .ant-row {
  padding: 4px 0;
}
.blockInfoData > div {
  padding: 72px 32px 32px 32px;
  background-position: top right;
  background-size: 240px;
  background-repeat: no-repeat;
  background-color: var(--SD075);
  border-radius: 16px;
}
.blockInfoData > div:first-child {
  background-image: url(bg-40.png);
}
.blockInfoData > div:first-child + div {
  background-image: url(53.png);
}
.blockInfoData > div:first-child + div + div {
  background-image: url(13.png);
}
.main-page {
  background-image: url(jfin-bg.png);
  background-size: 100%;
  background-position: 0 -40%;
  background-repeat: no-repeat;
  padding: 0 32px 80px 32px;
}
.blockInfoItem {
  display: flex;
  flex-direction: column;
}

.flexSpaceAround {
  display: flex;
  justify-content: space-around;
  flex: 0.4;
}

.ant-input-number {
  width: 100%;
}
.ant-table-thead > tr > th {
  background-color: var(--SD075);
  border-bottom-color: var(--SD025);
  color: var(--SL075);
}
.ant-table {
  background-color: var(--SD050);
  color: var(--SL075);
}
.ant-table-tbody > tr > td {
  border-bottom-color: var(--SD025);
}
.ant-menu {
  background-color: var(--SD075);
  color: var(--SL025);
  border-bottom-color: var(--SD050);
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: var(--SD075);
}
.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected {
  color: var(--S100);
}
.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected::after {
  border-bottom-color: var(--S100);
}
.ant-menu .ant-menu-overflow-item.ant-menu-item:hover {
  color: var(--S075);
}
.ant-menu .ant-menu-overflow-item.ant-menu-item:hover::after {
  border-bottom-color: var(--S075);
}
.ant-btn-primary {
  background: var(--S075);
  border-color: var(--S075);
}
.ant-btn-primary,
.ant-btn-default {
  border-radius: 20px;
}
.ant-btn-primary:hover {
  background: var(--S100);
  border-color: var(--S100);
}
[class*="ant-btn"] {
}
.header {
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.header nav {
  margin-left: 64px;
}
.header nav a {
  color: var(--SL100);
  font-display: inline-block;
}
.header nav a:not(:last-child) {
  margin-right: 24px;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled .ant-pagination-item-link:hover {
  background-color: var(--SD025);
  border-color: var(--SD025);
  color: var(--SL100);
}
.ant-pagination-item-active {
  background-color: var(--SD010);
  border-color: var(--SL075);
}
.ant-pagination-item-active:hover {
  background-color: var(--SD025);
  border-color: var(--SL075);
}
.ant-pagination-item-active a,
.ant-pagination-item-active:hover a {
  color: var(--SL050);
  border-color: var(--SD010);
}
.testnet {
  width: 100%;
  position: relative;
}
.testnet > span {
  color: #ffe811;
  font-size: 50px;
  width: 300px;
  position: absolute;
  left: calc(50% - 150px);
  z-index: 100;
  top: -20px;
  background: rgb(51 98 227 / 80%);
  border-radius: 30px;
  text-align: center;
}
